.Business_main_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 7rem;
}
.sub_main_bus_div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
}
.Business_heading_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}
.Business_card_main {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  gap: 1rem;
}
.Business_card_div {
  width: 95%;
  height: 450px;
  background-color: #e30a17;
  border-radius: 8px;
  display: grid;
  grid-template-rows: 55% 45%;
  place-items: center;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
}
.div_of_show_details_btn {
  position: absolute;
  bottom: 7px;
  right: 10px;
  background-color: #fff;
  border-radius: 8px;
  padding: 6px 12px;
  color: #e30a17;
  transition: all 0.3s ease-in-out;
  border: 1px solid #fff;
}
.div_of_show_details_btn:hover {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.div_of_show_details_btn > p {
  font-size: 14px;

  font-family: "Be Viettnam Pro Regular";
  margin-bottom: 0;
  cursor: pointer;
}

.image_bus_div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image_bus_div > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.div_bus_name {
  height: 40px;
  background-color: #bebebe;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.div_bus_name > h2 {
  text-align: center;
  font-family: "Be Viettnam Pro Regular";
  color: #000000;
  font-size: 18px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.text_bus_div_main {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}
.text_dtails_div {
  width: 95%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.text_dtails_div > p {
  font-size: 14px;
  color: #fff;
  font-family: "Be Viettnam Pro Regular";
  margin-bottom: 0;
}
.text_dtails_div > a {
  text-decoration: none;
  color: #fff;
  font-family: "Be Viettnam Pro Regular";
}
.text_dtails_div > a:hover {
  text-decoration: none;
  color: #fff;
  font-family: "Be Viettnam Pro Regular";
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #ff3d00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
.pagination_div_main {
  width: 90%;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.total_page {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.total_page > p {
  margin: 0;
  font-family: "Be Viettnam Pro Regular";
  font-size: 14px;
  color: #000000;
}
.total_page > h3 {
  margin: 0;
  font-family: "Be Viettnam Pro Regular";
  font-size: 18px;
  color: #e30a17;
}
.pagination_div {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: end;
}
/*? Hover effect on pagination items */
.page-link:hover {
  background-color: black !important;
  color: white !important;
}
.page-link:focus {
  background-color: black !important;
  color: white !important;
}

/*? Active pagination item styling */
/* .pagination_div .mdbpagination .mdbpaginationitem.active .mdbpaginationlink {
} */

.active > .page-link {
  background-color: #e30a17 !important;
  color: white !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .Business_card_main {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
    gap: 1rem;
  }
  .pagination_div_main {
    width: 90%;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
}
