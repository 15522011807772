.main_Countries_div {
  margin-top: 110px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub_Countries_div {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inside_Countries_div {
  width: 95%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.heading_search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search_div {
  width: 300px;
  height: 40px;
  border: 1px solid #e30a17;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 8px;
}
.search_div > input {
  width: 95%;
  height: 95%;
  border: none;
  outline: none;
  background-color: transparent;
}
.heading {
  width: 100%;
  padding: 10px 0px 0px 10px;
}
.heading > h1 {
  font-family: "Be Viettnam Pro Bold";
  font-size: 35px;
  color: black;
}

.Countries_data_div {
  width: 90%;
  height: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}
.div_name_of_Countries {
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  padding: 0 10px;
  align-items: center;
  border-radius: 8px;
  background-color: #e30a17;
  transition: all 0.5s ease-in-out;
}
.div_name_of_Countries:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: scale(1.1);
}
.div_name_of_Countries > h3 {
  margin-bottom: 0;
  font-size: 18px;
  font-family: "Be Viettnam Pro SemiBold";
  color: #fff;
}
