.main_login_page_div {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #e30a17; */
  background: url("../../assets/Lgin_bg.png");
  background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.sub_main_div_of_login {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.main_login_card {
  width: 80%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_form_div {
  width: 70%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  background: #e30a18cd;
  backdrop-filter: saturate(180%) blur(15px);
  /* background-color: #e30a17; */
  border-radius: 10px;
  box-shadow: #00000059 0px 10px 25px;
}
.div_of_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
}
.div_of_logo > p {
  font-size: 14px;
  color: #fff;
  font-family: "Be Viettnam Pro Medium";
}

/* form styleing start here */

.login_form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 80%;
}
.Email_box_login {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 80%;
}
.Email_box_login > label {
  font-family: "Be Viettnam Pro Medium";
  color: #f4f4f4;
  letter-spacing: 1px;
  font-size: 15px;
}
.input_div_login {
  width: 100%;
  height: 35px;
  overflow: hidden;
  display: grid;
  place-items: center;
  border: 0.5px solid #f4f4f4b1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input_div_login > input {
  width: 95%;
  height: 85%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 18px;
  color: #f4f4f4;
  font-family: "Be Viettnam Pro Medium";
}
.input_div_pass {
  width: 100%;
  height: 35px;
  overflow: hidden;
  /* display: grid; */
  /* place-items: center; */
  border: 0.5px solid #f4f4f4b1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select_div {
  width: 100%;
  height: 35px;
  overflow: hidden;

  border: 0.5px solid #f4f4f439;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: transparent;
  color: #f4f4f4;
}
.select_div_inner {
  width: 90%;
  background-color: transparent;
  color: #f4f4f4;
  outline: none;
  border: none;
}
.select_div_inner > option {
  background-color: transparent !important;
  color: #000000 !important;
}
.input_div_pass > input {
  width: 90%;
  height: 85%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 18px;
  color: #f4f4f4;
  font-family: "Be Viettnam Pro Medium";
}
.Forgot_text > p {
  color: #f4f4f4;
  font-family: "Be Viettnam Pro Medium";
  font-size: 15px;
  cursor: pointer;
}
.btn_primry {
  padding: 10px 15px;
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: #ffffff;
  color: #e30a17;
  font-size: 14px;
  font-family: "Be Viettnam Pro Medium";
  margin-top: 0rem;
  font-size: 15px;
  cursor: pointer;
}
.btn_primry:hover {
  border: 1px solid #f4f4f4;
  background-color: transparent;
  transition: all ease 0.5s;
  color: #f4f4f4;
}
.errors_msg_login {
  color: #ffffff;
  font-size: 12px;
  font-family: "Be Viettnam Pro Medium";
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.div_of_button_ {
  width: 80%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.div_forgot_password > p {
  margin-bottom: 0rem;
  color: #ffffff;
  font-size: 14px;
  font-family: "Be Viettnam Pro Medium";
  cursor: pointer;
}

.main_signin_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_signin_div > p {
  color: #e30a17;
  font-size: 14px;
  margin-bottom: 0rem;
  font-family: "Be Viettnam Pro Medium";
}
.navlink {
  color: #252525;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 0rem;
  font-family: "Be Viettnam Pro Medium";
}
@media screen and (max-width: 1150px) {
  .main_login_card {
    width: 100%;
  }
  .main_form_div {
    width: 90%;
  }
  .div_of_logo {
    width: 70%;
  }
  .div_of_logo > img {
    width: 80%;
  }
  .div_of_logo > p {
    font-size: 14px;
  }
  .Email_box {
    width: 90%;
  }
  .div_of_button_ {
    width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .main_login_card {
    width: 100%;
  }
  .main_form_div {
    width: 100%;
  }
  .div_of_logo > img {
    width: 80%;
  }
  .div_of_logo > p {
    font-size: 12px;
  }
  .login_form {
    width: 100%;
  }
  .Email_box {
    width: 85%;
  }
  .div_of_button_ {
    width: 85%;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #ff3d00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
