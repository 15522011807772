.main_form_div_SU {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  background: #e30a18cd;
  backdrop-filter: saturate(180%) blur(15px);
  padding: 2rem 0;
  border-radius: 10px;
  box-shadow: #00000059 0px 10px 25px;
}

.su_main_form_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.su_main_form_div::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.back_btn_div_auth {
  position: absolute;
  cursor: pointer;
  top: 5rem;
  left: 4rem;
  border: 1px solid #ffffff;
  color: #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.4s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.back_btn_div_auth:hover {
  background-color: #ffffff;
  color: #e30a17;
  border: 1px solid #ffffff;
}

.su_name_main,
.su_Company_main,
.su_Email_number_main,
.su_Dob_add_main,
.su_City_add_main,
.su_Password_add_main {
  display: grid;
  grid-template-columns: 50% 50%;
  place-items: center;
  width: 100%;
}
/* .su_Password_add_main {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.su_fname_div,
.su_Company_div,
.su_Email_div,
.su_Mobile_div,
.su_Dob_div,
.su_add_div,
.su_City_div,
.su_Country_div {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
.su_fname_div > label,
.su_Email_div > label,
.su_Mobile_div > label,
.su_Dob_div > label,
.su_add_div > label,
.su_City_div > label,
.su_Country_div > label,
.su_Company_div > label {
  font-family: "Be Viettnam Pro Medium";
  color: #f4f4f4;
  letter-spacing: 1px;
  font-size: 15px;
}
.su_fname_main_input_div,
.su_email_main_input_div,
.su_Mobile_main_input_div,
.su_Address_main_input_div,
.su_Dob_main_input_div,
.su_city_main_input_div,
.su_Country_main_input_div {
  width: 100%;
  height: 35px;
  overflow: hidden;
  display: grid;
  place-items: center;
  border: 0.5px solid #f4f4f4b1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}
.su_fname_main_input_div > input,
.PhoneInput_SU,
.PhoneInput_SU > input,
.react-datepicker-wrapper,
.react-datepicker__input-container,
.date_picker_input,
.su_Dob_main_input_div > input,
.su_Address_main_input_div > input,
.su_email_main_input_div > input,
.su_city_main_input_div > input,
.su_Country_main_input_div > select {
  width: 95%;
  height: 85%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: #f4f4f4;
  font-family: "Be Viettnam Pro Medium";
}
.date_picker_input::placeholder {
  color: #f4f4f4;
  font-family: "Be Viettnam Pro Medium";
}

@media screen and (max-width: 1140px) {
  .main_form_div_SU {
    height: max-content;
  }
}
@media screen and (max-width: 840px) {
  .main_form_div_SU {
    height: max-content;
    width: 100%;
  }
}
@media screen and (max-width: 766px) {
  .su_main_form_div {
    height: 70vh;
  }
  .main_form_div_SU {
    width: 100%;
    height: 90%;
  }
  .back_btn_div_auth {
    position: absolute;
    cursor: pointer;
    top: 2rem;
    left: 2rem;
    border: 1px solid #ffffff;
    color: #ffffff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    transition: all 0.4s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .su_name_main,
  .su_Company_main,
  .su_Email_number_main,
  .su_Dob_add_main,
  .su_City_add_main {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    gap: 1rem;
    width: 100%;
  }
  .su_Password_add_main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #ff3d00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
