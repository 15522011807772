.main_sec2_div {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e30a17;
}
.submain_div_of_sec2 {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_text_content_div_sec2 {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 5rem 0rem;
  /* background-color: rgb(70, 36, 43);  */
}
.main_text_content_div_sec2 > img {
  width: 60%;
  aspect-ratio: 2/1;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .main_text_content_div_sec2 {
    width: 95%;
    padding: 3rem 0rem;
    margin-left: 5%;
  }
  .main_text_content_div_sec2 > img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
  }
}
