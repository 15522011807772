.main_heading_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.heading_detaisl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10px 0px 0px 10px;
}
.heading_detaisl > h1 {
  font-family: "Be Viettnam Pro Bold";
  font-size: 35px;
  color: black;
}

.main_details_bus {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.bus_imaegs_main {
  width: 90%;
  height: 90%;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  gap: 1rem;
}
.img_box {
  width: 350px;
  height: 200px;
  overflow: hidden;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
}
.img_box > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.Company_deatails {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  flex-direction: column;
  gap: 1rem;
}
.Company_name_div {
  width: 90%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.Company_div1 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  padding: 1rem;
  background-color: #e30a17;
  /* background-image: linear-gradient(
      45deg,
      rgb(10, 10, 10) 0%,
      rgb(10, 10, 10) 60%,
      rgb(35, 35, 35) 60%,
      rgb(35, 35, 35) 62%,
      rgb(60, 60, 60) 62%,
      rgb(60, 60, 60) 76%,
      rgb(85, 85, 85) 76%,
      rgb(85, 85, 85) 77%,
      rgb(109, 109, 109) 77%,
      rgb(109, 109, 109) 86%,
      rgb(168, 168, 168) 86%,
      rgb(168, 168, 168) 92%,
      rgb(255, 97, 110) 92%,
      rgb(255, 97, 110) 100%
    ),
    linear-gradient(
      112.5deg,
      rgb(10, 10, 10) 0%,
      rgb(10, 10, 10) 60%,
      rgb(35, 35, 35) 60%,
      rgb(35, 35, 35) 62%,
      rgb(60, 60, 60) 62%,
      rgb(60, 60, 60) 76%,
      rgb(85, 85, 85) 76%,
      rgb(85, 85, 85) 77%,
      rgb(109, 109, 109) 77%,
      rgb(109, 109, 109) 86%,
      rgb(168, 168, 168) 86%,
      rgb(168, 168, 168) 92%,
      rgb(255, 97, 110) 92%,
      rgb(255, 97, 110) 100%
    ),
    linear-gradient(
      45deg,
      rgb(10, 10, 10) 0%,
      rgb(10, 10, 10) 60%,
      rgb(35, 35, 35) 60%,
      rgb(35, 35, 35) 62%,
      rgb(60, 60, 60) 62%,
      rgb(60, 60, 60) 76%,
      rgb(85, 85, 85) 76%,
      rgb(85, 85, 85) 77%,
      rgb(109, 109, 109) 77%,
      rgb(109, 109, 109) 86%,
      rgb(168, 168, 168) 86%,
      rgb(168, 168, 168) 92%,
      rgb(255, 97, 110) 92%,
      rgb(255, 97, 110) 100%
    ),
    linear-gradient(90deg, rgb(22, 22, 22), rgb(158, 12, 23));
  background-blend-mode: overlay, overlay, overlay, normal; */
}
.Company_div1 > p {
  margin: 0;
  text-transform: capitalize;
  color: #000000;
  font-family: "Be Viettnam Pro Medium";
  font-size: 16px;
}
.Company_div1 > h4 {
  margin: 0;

  color: #ffffff;
  font-family: "Be Viettnam Pro Medium";
  font-size: 22px;
}
.Company_div1 > a {
  margin: 0;
  text-decoration: none;
  color: #ffffff;
  font-family: "Be Viettnam Pro Medium";
  font-size: 16px;
}
.Company_div1 > a:hover {
  margin: 0;
  text-decoration: none;
  color: #ffffff;
  font-family: "Be Viettnam Pro Medium";
  font-size: 16px;
}
.company_desc_div {
  width: 90%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  padding: 1rem;
  background-color: #e30a17;
  /* background-image: linear-gradient(
      45deg,
      rgb(10, 10, 10) 0%,
      rgb(10, 10, 10) 60%,
      rgb(35, 35, 35) 60%,
      rgb(35, 35, 35) 62%,
      rgb(60, 60, 60) 62%,
      rgb(60, 60, 60) 76%,
      rgb(85, 85, 85) 76%,
      rgb(85, 85, 85) 77%,
      rgb(109, 109, 109) 77%,
      rgb(109, 109, 109) 86%,
      rgb(168, 168, 168) 86%,
      rgb(168, 168, 168) 92%,
      rgb(255, 97, 110) 92%,
      rgb(255, 97, 110) 100%
    ),
    linear-gradient(
      112.5deg,
      rgb(10, 10, 10) 0%,
      rgb(10, 10, 10) 60%,
      rgb(35, 35, 35) 60%,
      rgb(35, 35, 35) 62%,
      rgb(60, 60, 60) 62%,
      rgb(60, 60, 60) 76%,
      rgb(85, 85, 85) 76%,
      rgb(85, 85, 85) 77%,
      rgb(109, 109, 109) 77%,
      rgb(109, 109, 109) 86%,
      rgb(168, 168, 168) 86%,
      rgb(168, 168, 168) 92%,
      rgb(255, 97, 110) 92%,
      rgb(255, 97, 110) 100%
    ),
    linear-gradient(
      45deg,
      rgb(10, 10, 10) 0%,
      rgb(10, 10, 10) 60%,
      rgb(35, 35, 35) 60%,
      rgb(35, 35, 35) 62%,
      rgb(60, 60, 60) 62%,
      rgb(60, 60, 60) 76%,
      rgb(85, 85, 85) 76%,
      rgb(85, 85, 85) 77%,
      rgb(109, 109, 109) 77%,
      rgb(109, 109, 109) 86%,
      rgb(168, 168, 168) 86%,
      rgb(168, 168, 168) 92%,
      rgb(255, 97, 110) 92%,
      rgb(255, 97, 110) 100%
    ),
    linear-gradient(90deg, rgb(22, 22, 22), rgb(158, 12, 23));
  background-blend-mode: overlay, overlay, overlay, normal; */
}
.company_desc_div > p {
  margin: 0;
  text-transform: capitalize;
  color: #000000;
  font-family: "Be Viettnam Pro Medium";
  font-size: 16px;
}
.company_desc_div > h4 {
  margin: 0;
  text-transform: capitalize;
  color: #ffffff;
  font-family: "Be Viettnam Pro Regular";
  font-size: 14px;
  line-height: 28px;
}

@media screen and (max-width: 767px) {
  .bus_imaegs_main {
    flex-direction: column;
  }
  .Company_name_div {
    width: 90%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}
