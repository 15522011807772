.main_div_of_sec1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* background: #313131 !important; */
  justify-content: center;
  padding: 4rem 0;
}
.mySwiper,
.swiper {
  width: 90% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* background: #4e4242 !important; */
}

.swiper_innerdiv {
  text-align: center;
  font-size: 18px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100% !important;
  /* background: #ff9e9e !important; */
}
.main_div_of_swiper {
  width: 90%;
  height: 90%;
  /* background: #a30303 !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: relative;
  z-index: 1;
  color: white !important;
}

.image_div_of_swiper {
  display: block;
  width: 90%;
  height: 90%;
  /* box-shadow: #00000049 0px 0px 8px; */
  border-radius: 12px !important;
  overflow: hidden;
}
.image_div_of_swiper > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.main_div_of_swiper::before {
  content: "";
  border-radius: 12px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000000;
  z-index: -1;
}

.swiper-slide > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-prev,
.swiper-button-next {
  color: #e30a17 !important;
}

@media screen and (max-width: 767px) {
  .mySwiper,
  .swiper {
    width: 100% !important;
  }
  .main_div_of_swiper {
    width: 100% !important;
  }
  .swiper-wrapper {
    width: 90% !important;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 25px !important;
  }
}
