.All-Conatinor-perfect-divv {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  margin-top: 110px;
  justify-content: center;
}
.All-Containor-perfect-second-divv {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  flex-direction: column;
  /* margin-top: 2rem; */
}

.main_myprofile_div {
  width: 100%;
  height: 100%;
  background-color: #00000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: #38383859 0px 5px 15px;
}

.profile_img_div {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: #ffffff40 0px 54px 55px, #ffffff1f 0px -12px 30px,
    #ffffff1f 0px 4px 6px, #ffffff2b 0px 12px 13px, #ffffff17 0px -3px 5px;
}
.profile_img_div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding: 0 80px;
  text-align: center;
  height: 100%;
  padding-bottom: 2rem;
  /* background: #050808; */
}

.banner > img {
  width: 60vw;
  transition: 0.1s linear;
}

.banner h2 {
  font-weight: 500;
  font-size: 30px;
  margin: 0 0 10px;
}

.banner p {
  margin: 0;
  line-height: 1.65;
  font-size: 17px;
  opacity: 0.7;
}
.banner2 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  gap: 50px;
  padding: 0 80px;
  text-align: center;
  height: 100%;
  padding-bottom: 2rem;
  /* background: #050808; */
}

.banner2 > img {
  width: 60vw;
  transition: 0.1s linear;
}

.banner2 h2 {
  font-weight: 500;
  font-size: 30px;
  margin: 0 0 10px;
}

.banner2 p {
  margin: 0;
  line-height: 1.65;
  font-size: 17px;
  opacity: 0.7;
}

@media (width >= 420px) {
  .banner > img {
    width: 40vw;
  }
  .banner2 > img {
    width: 40vw;
  }
}

@media (width >= 648px) {
  .banner > img {
    width: 30vw;
  }
  .banner2 > img {
    width: 30vw;
  }
}

.waves > use {
  animation: move-forever 2s -2s linear infinite;
}

.waves > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 6s;
}
.waves > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 3s;
}

@keyframes move-forever {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

.svg-container {
  /* position: absolute;
  left: 0;
  bottom: 0; */
  width: 100%;
  height: 50vw;
  max-height: 500px;
}

/* ? main_edit_profile_div*/
.main_edit_profile_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btn_of_edit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem;
}
.theered_btn {
  padding: 10px 15px;
  border-radius: 10px;
  color: whitesmoke;
  background-color: #c3002f;
  border: 1px solid #c3002f;
  cursor: pointer;
}
.theered_btn:hover {
  color: whitesmoke;
  background-color: #000;
  border: 1px solid #000;
}
.edit_profile_from_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.name_div_edit {
  width: 350px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}
#form1 {
  outline: none !important;
}

.errors_msg_p_e {
  color: #ff002f;
  font-size: 8px !important;
}
.profile_show_data {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.profile_show_data > h2 {
  color: #c3002f;
  font-family: "Be Viettnam Pro SemiBold";
  margin: 0;
}
.profile_show_data > p {
  color: #c3002f;
  font-family: "Be Viettnam Pro Regular";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.custom-file-upload {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.custom-file-upload > img {
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: cover;
}
.editicon_ {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  bottom: 10%;
  right: 15%;
  border-radius: 50%;
}
.Edit_profile_form_ {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.fname,
.email_div,
.number_div,
.age_div,
.sex_div,
.contry_div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
.fname > label,
.email_div > label,
.number_div > label,
.age_div > label,
.sex_div > label,
.contry_div > label {
  color: #c3002f;
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
}
.fname_input,
.email_input,
.mobile_number_input,
.age_input,
.sex_input,
.country_input {
  width: 300px;
  height: 30px;
  border: 1px solid #c3002f;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0px 10px;
  border-radius: 6px;
}
.fname_input > input,
.email_input > input,
.age_input > input,
.sex_input > input,
.country_input > input,
.fname_input > select {
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  color: #c3002f;
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
}
.PhoneInputInput {
  width: 230px;
  outline: none;
  border: none;
  background-color: transparent !important;
  padding-left: 10px;
  color: #c3002f;
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
}
.fname_input > label,
.email_input > label,
.age_input > label,
.sex_input > label,
.country_input > label {
  color: white;
}

@media screen and (max-width: 768px) {
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    padding: 0px 20px;
    text-align: center;
    height: 100%;
    padding-bottom: 2rem;
    /* background: #050808; */
  }
  .banner2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    padding: 0px 20px;
    text-align: center;
    height: 100%;
    padding-bottom: 2rem;
    /* background: #050808; */
  }
}
