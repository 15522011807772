.business_container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  /* background-image: url("../../assets/Lgin_bg.png"); */
  /* filter: blur(10px);
  -webkit-filter: blur(8px); */
}
.sub_main_div_of_login_add {
  width: 95%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.main_login_card_add {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_form_div_add {
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  background: #ffffff;
  backdrop-filter: saturate(180%) blur(15px);
  padding: 1rem 0 0 0;
  border-radius: 10px;
  /* box-shadow: #00000059 0px 5px 15px; */
  overflow-y: hidden;
}
/* add_business_from */
.add_business_from {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;
  padding: 2rem 0rem;
}
.add_business_from::-webkit-scrollbar {
  display: none;
}
.compony_details_main_div,
.compney_info_main_div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  gap: 1rem;
}

.compony_name_div,
.compny_category_div,
.compney_found_div,
.compony_employ_div,
.compony_working_day_div,
.Business_Hours_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.compony_description_main_div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.description_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.compony_name_div > label,
.compny_category_div > label,
.compney_found_div > label,
.compony_employ_div > label,
.compony_working_day_div > label {
  width: 90%;
  font-family: "Be Viettnam Pro Medium";
  color: #f4f4f4;
  letter-spacing: 1px;
  font-size: 15px;
}
.description_div > label {
  width: 90%;
  font-family: "Be Viettnam Pro Medium";
  color: #f4f4f4;
  letter-spacing: 1px;
  font-size: 15px;
  text-align: start;
}
.compony_input_div {
  width: 100%;
  height: 35px;
  overflow: hidden;
  display: grid;
  place-items: center;
  border: 0.5px solid #f4f4f4b1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}
.compony_input_div > input,
.compony_input_div > select {
  width: 95%;
  height: 85%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: #f4f4f4;
  font-family: "Be Viettnam Pro Medium";
}
.compony_input_div > input::placeholder {
  color: #ffffff;
}
.Desription_input_div > textarea::placeholder {
  color: #ffffff;
}
.Desription_input_div {
  width: 100%;
  height: 100px;
  overflow: hidden;
  display: grid;
  place-items: center;
  border: 0.5px solid #f4f4f4b1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}
.Desription_input_div > textarea {
  width: 100%;
  height: 85% !important;
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;
  font-size: 14px;
  color: #f4f4f4;
  font-family: "Be Viettnam Pro Medium";
}

.time_input_main_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.Business_Hours_div > label {
  width: 100%;
  font-family: "Be Viettnam Pro Medium";
  color: #f4f4f4;
  letter-spacing: 1px;
  font-size: 15px;
}

.time_input_div {
  width: 45%;
  height: 35px;
  overflow: hidden;
  display: grid;
  place-items: center;
  border: 0.5px solid #f4f4f4b1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}
.time_input_div > input {
  width: 95%;
  height: 85%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: #f4f4f4;
  font-family: "Be Viettnam Pro Medium";
}

.time_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.compony_input_div_time > input {
  width: 95%;
  height: 85%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: #f4f4f4;
  font-family: "Be Viettnam Pro Medium";
}

.compony_input_div_time {
  width: 100%;
  height: 90%;
  overflow: hidden;
  display: grid;
  place-items: center;
  border: 0.5px solid #f4f4f4b1 !important;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  color: white !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 0px !important;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 !important;
}

.start_time_div > label {
  width: 100%;
  font-family: "Be Viettnam Pro Medium";
  color: #f4f4f4;
  letter-spacing: 1px;
  font-size: 15px;
}

.ulopd_image_div_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.image_upload_main_div {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  gap: 1rem;
}
.img_box_buisness {
  width: 350px;
  height: 200px;
  background-color: #e53d32;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_box_buisness > input {
  display: none;
}
.uploadicon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.previewimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img_box > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.ulopd_image_div_section > h3 {
  font-family: "Be Viettnam Pro SemiBold";
  color: #040404;
  letter-spacing: 1px;
  font-size: 20px;
}

.btn_primry_Add_business {
  padding: 10px 15px;
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: #e30a17;
  color: #ffffff;
  font-size: 14px;
  font-family: "Be Viettnam Pro Medium";
  margin-top: 0rem;
  font-size: 15px;
  cursor: pointer;
}
.btn_primry_Add_business:hover {
  border: 1px solid #e30a17;
  background-color: transparent;
  transition: all ease 0.5s;
  color: #e30a17;
}
.back_btn_mybus_div {
  position: absolute;
  cursor: pointer;
  top: 2rem;
  left: 2rem;
  border: 1px solid #000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.4s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
@media screen and (max-width: 767px) {
  .image_upload_main_div {
    flex-direction: column;
  }
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #e30a17;
  border: none;
  cursor: pointer;
  z-index: 999;
}

.delete-icon img {
  width: 20px;
  height: 20px;
  z-index: 999;
}
