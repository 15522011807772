.main_sec4_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0rem;
}
.sub_main_div_of_sec4 {
  width: 85%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.heding_div_of_sec4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heding_div_of_sec4 > h2 {
  color: #000;
  font-family: "Be Viettnam Pro SemiBold";
  font-size: 49px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-top: 0px;
  margin-bottom: 1.5rem;
}

.sec4_main_content_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem;
}
.main_card_div {
  width: 95%;
  height: 95%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.card_sec4 {
  width: 100%;
  height: 450px;
  background-color: #ffffff;
  backdrop-filter: saturate(180%) blur(15px);
  border-radius: 12px;
  display: grid;
  grid-template-rows: 60% 30% 10%;
  overflow: hidden;
  place-items: center;
  /* gap: 0.5rem; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* position: relative; */
}
.img_card_div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
}
.img_card_div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text_card_div {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.text_card_div > h3 {
  font-family: "Be Viettnam Pro Bold";
  font-size: 22px;
  color: #000;
}
.text_card_div > p {
  font-family: "Be Viettnam Pro Regular";
  font-size: 14px;
  color: #000;
  text-align: center;
  margin-bottom: 0;
}
.link_sec4 {
  /* position: absolute;
  bottom: -3px;
  right: 10px; */
  font-size: 14px;
  font-family: "Be Viettnam Pro Regular";
  color: #e30a17;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.link_sec4:hover {
  background-color: #e30a17;
  color: white;
  padding: 8px 10px;
  border-radius: 12px 12px;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .heding_div_of_sec4 > h2 {
    color: #000;
    font-family: "Be Viettnam Pro SemiBold";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-top: 0px;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    text-align: center;
  }
  .main_card_div {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  .card_sec4 {
    grid-template-rows: 58% 32%;
  }
  .main_sec4_div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0rem;
  }
}

@media screen and (max-width: 376px) {
  .text_card_div > h3 {
    font-family: "Be Viettnam Pro Bold";
    font-size: 18px;
    color: #000;
  }
}
