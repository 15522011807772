.Modalbody {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(0deg, #e30a18c4 0%, rgba(255, 255, 255, 1) 80%); */
}
.model_inner_main_div {
  width: 95%;
  height: 80vh;
  overflow-y: scroll;
}

/* Hide scrollbar for WebKit browsers */
.model_inner_main_div::-webkit-scrollbar {
  display: none;
}

/* Optional: for a smoother scroll experience */
.model_inner_main_div {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.model_inner_main_div > p {
  font-family: "Be Viettnam Pro Regular";
  font-size: 14px;
  color: #000;
}
.title_of_li {
  font-family: "Be Viettnam Pro Bold";
  font-size: 18px;
}
.model_ul > li {
  font-family: "Be Viettnam Pro Regular";
  font-size: 14px;
  color: #000;
}
.bold_text {
  font-family: "Be Viettnam Pro Bold";
  font-size: 16px;
}
.model_ul {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

/* ? forgot password */
.forgot_main_div {
  width: 100%;
  height: 100%;
}

.Modalbody_forgot {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  /* background: linear-gradient(0deg, #e30a18c4 0%, rgba(255, 255, 255, 1) 80%); */
}
.forget_form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.enter_email_div {
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}
.enter_email_div > label {
  font-size: 18px;
  margin: 0;
  color: #000;
  font-family: "Be Viettnam Pro Medium";
}
.email_enter_input {
  width: 100%;
  height: 35px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.email_enter_input > input {
  width: 98%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.btn_primry_fp {
  padding: 8px 15px;
  border-radius: 6px;
  border: none;
  outline: none;
  border: 1px solid #e30a17;
  background-color: #e30a17;
  color: #ffffff;
  font-size: 14px;
  font-family: "Be Viettnam Pro Medium";
  margin-top: 0rem;
  font-size: 15px;
  cursor: pointer;
}
.btn_primry_fp:hover {
  border: 1px solid #e30a17;
  background-color: transparent;
  transition: all ease 0.5s;
  color: #e30a17;
}

.email_enter_input > input:-webkit-autofill,
.email_enter_input > input:-webkit-autofill:hover,
.email_enter_input > input:-webkit-autofill:focus,
.email_enter_input > input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #191919;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.errors_msg_p_fp {
  font-family: "Be Viettnam Pro Medium";
  margin: 0;
  color: #e30a17;
  font-size: 12px;
}

.main_change_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}
.pass_form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.oldpass > label {
  color: #191919;
  font-family: "BeVietnamPro-Regular";
  font-size: 14px;
}
.old_pass_input_div {
  width: 300px;
  height: 30px;
  border: 1px solid #191919;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
  border-radius: 6px;
}
.old_pass_input_div > input {
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  color: #191919;
  font-family: "BeVietnamPro-Regular";
  font-size: 14px;
}

.btn_of_delte_model {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  /* margin: 0rem 1rem 0rem 0rem; */
}
