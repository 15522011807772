.sub_main_div_of_LM1 {
  width: 90%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0rem;
}
.inner_div_LM3 {
  width: 90%;
  height: max-content;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1.5rem;
}
.inner_div_LM3 > p {
  font-family: "Be Viettnam Pro Regular";
  font-size: 16px;
  text-align: center;
  margin-bottom: 0px;
}
.text_LM3 {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2rem 0rem;
}

.image_of_LM3 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.image_of_LM3 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.PRE_btn {
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 48px;
  background-color: #e13232;
  color: #fff;
  padding: 12px 29px;
  font-family: "Be Viettnam Pro SemiBold";
  margin: 15px 0px;
  text-decoration: none;
}
.PRE_btn:hover {
  color: #fff;
}

.bold_text_a {
  font-family: "Be Viettnam Pro Bold";
  font-size: 16px;
  color: #000000;
  cursor: pointer;
}
.bold_text_a:hover {
  font-family: "Be Viettnam Pro Bold";
  font-size: 16px;
  color: #e13232;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .inner_div_LM3 {
    grid-template-columns: 1fr;
  }
  .PRE_btn1 {
    font-size: 12px;
  }
}
