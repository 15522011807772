.main_LM1_div {
  padding-top: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
  position: relative;
}
.back_btn_div {
  position: absolute;
  cursor: pointer;
  top: 10rem;
  left: 4rem;
  border: 1px solid #000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.4s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.back_btn_div:hover {
  background-color: #e30a17;
  color: #ffffff;
  border: 1px solid #ffffff;
}
/* .back_btn_div:hover {
  background-color: #ffffff;
  color: #e30a17;
  border: 1px solid #e30a17;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
} */
.back_btn_div > span {
  font-size: 20px;
}
.sub_main_div_of_LM1 {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0rem;
}
.heding_div > h1 {
  font-family: "Be Viettnam Pro SemiBold";
  font-size: 35px;
}
.inner_div_LM1 {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.inner_div_LM1 > p {
  font-family: "Be Viettnam Pro Regular";
  font-size: 16px;
  text-align: center;
  margin-bottom: 0px;
}

.main_card_div_LM1 {
  width: 95%;
  height: 95%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.card_sec4_LM1 {
  width: 100%;
  height: 470px;
  background-color: #ffffff;
  backdrop-filter: saturate(180%) blur(15px);
  border-radius: 12px;
  display: grid;
  grid-template-rows: 50% 50%;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  place-items: center;
  gap: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
}

.text_card_div_LM1 {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
}
.text_card_div_LM1 > h3 {
  font-family: "Be Viettnam Pro Bold";
  font-size: 20px;
  color: #000;
  text-align: center;
}
.text_card_div_LM1 > p {
  font-family: "Be Viettnam Pro Regular";
  font-size: 12px;
  color: #000;
}
.img_card_div_LM {
  width: 90%;
  height: 90%;
  overflow: hidden;
  border-radius: 12px;
}
.img_card_div_LM > img {
  width: 100%;
  height: 100%;
  /* aspect-ratio: 3/3; */
  object-fit: cover;
}

@media screen and (max-width: 1130px) {
  .main_card_div_LM1 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .main_card_div_LM1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .main_LM1_div {
    padding-top: 143px;
  }
  .back_btn_div {
    top: 8rem;
    left: 2rem;
  }
  .sub_main_div_of_LM1 {
    gap: 1rem !important;
    padding: 3rem 0rem !important;
  }
  .heding_div > h1 {
    font-size: 25px;
    margin-bottom: 0;
  }
}
